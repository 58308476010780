import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie'
import { determineClientUrlAndRedirectIfNeeded } from './determineClientUrlAndRedirectIfNeeded'
import { i18n } from '@lingui/core'

import RouterProvider from './providers/RouterProvider'
import store from './redux-store/store'
import { Loader } from './components/Loader/Loader'
import { ErrorMonitoring } from 'app/ErrorMonitoring'
import { BaseRoutes } from './components/app/BaseRoutes'
import { useFeatureFlags } from './featureFlags/useFeatureFlags'
import { FeatureFlags } from './featureFlags/FeatureFlags'
import { I18nProvider } from '@lingui/react'
import messages from 'locales/en/messages'

export const AppIndex = () => {
  i18n.load('en', messages)
  i18n.activate('en')
  const { getFlag, setFlag } = useFeatureFlags()

  const [redirect, setRedirect] = useState<any>()
  const [isLoading, setIsLoading] = useState(true)

  const { location } = window

  useEffect(() => {
    const determineRedirect = async () => {
      try {
        setIsLoading(true)
        const result = await determineClientUrlAndRedirectIfNeeded(
          getFlag,
          setFlag
        )
        setRedirect(result)
        setIsLoading(false)
      } catch (err) {
        console.log('redirect err', err)
        setIsLoading(false)
      }
    }
    if (location && location.pathname.includes('login/callback')) {
      setIsLoading(false)
    } else {
      determineRedirect()
    }
  }, [location])

  if (isLoading) {
    return <Loader />
  }

  if (!redirect || redirect === null) {
    return (
      <ErrorMonitoring>
        <>
          <FeatureFlags />
          <CookiesProvider>
            <I18nProvider i18n={i18n}>
              <Provider store={store}>
                <BrowserRouter>
                  <RouterProvider>
                    <BaseRoutes />
                  </RouterProvider>
                </BrowserRouter>
              </Provider>
            </I18nProvider>
          </CookiesProvider>
        </>
      </ErrorMonitoring>
    )
  }
  return null
}
